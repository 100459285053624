import {Route, Switch} from "react-router-dom";
import Login from "./login/Login";
import React, {useEffect} from "react";
import loadable from "@loadable/component";

const AuthCardRoutes = loadable(() => import('./AuthRoutes'));

const Auth = () => {

    useEffect(() => {
        AuthCardRoutes.preload();
    }, []);
    return (
        <>
            <Switch>
                <Route path="/" exact component={Login}/>
                <AuthCardRoutes/>
            </Switch>
        </>
    )
}
export default Auth;
