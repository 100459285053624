import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    user: {},
    loggedIn: false,
};
export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state,action) => {
            state.user = action.payload;
            state.loggedIn = true;
        },
        clearUser: (state) => initialState,
        updateUser: (state,action)=>{
            state.user = {...state.user,...action.payload};
        }
    },
});
export const {setUser,clearUser,updateUser} = slice.actions;

export default slice.reducer;
