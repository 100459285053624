import firebase from '../../api/firebase'
import {clearUser} from "./AuthSlice";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

export const AuthService = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const FirebaseLogin = async (mail, password,remember) => new Promise((resolve, reject) => {
        console.log(mail.replace(/\s/g, ''),password);
        firebase.auth().signInWithEmailAndPassword(mail.replace(/\s/g, ''), password).catch((error) => {
            console.log(error.code);
            switch (error.code) {
                case 'auth/user-disabled':
                    reject({message: 'Dit account is niet langer geldig'});
                    break;
                case 'auth/user-not-found':
                    reject({message: 'De combinatie van email en wachtwoord is niet geldig'});
                    break;
                case 'auth/wrong-password':
                    reject({message: 'De combinatie van email en wachtwoord is niet geldig'});
                    break;
                case 'auth/invalid-email':
                    reject({message: 'Geen geldige email adres opgegeven'});
                    break;
                default:
                    reject({message: 'Er ging iets onverwacht fout, neem contact op met de systeembeheerder'});
            }
        }).then((e) => {
            console.log(remember,'remember');
            firebase.auth().setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
            resolve(e);
        });
    });
    const FirebaseReset = async (mail) => new Promise((resolve, reject) => {
        firebase.auth().sendPasswordResetEmail(mail.replace(/\s/g, '')).catch((error) => {
            switch (error.code) {
                case 'auth/user-not-found':
                    reject({message: 'Gebruiker niet gevonden'});
                    break;
                case 'auth/invalid-email':
                    reject({message: 'Geen geldige email adres opgegeven'});
                    break;
                default:
                    reject({message: 'Er ging iets onverwacht fout, neem contact op met de systeembeheerder'});
            }
        }).then((e) => {
            resolve(e);
        });
    });

    // const CheckEmail = async (existingEmail) => new Promise(async (resolve, reject) => {
    //     auth.fetchSignInMethodsForEmail(existingEmail)
    //         .then(async (providers) => {
    //             if (Array.isArray(providers) && providers.length) {
    //                 if (providers.indexOf(auth.GoogleAuthProvider.PROVIDER_ID) !== -1) {
    //                     reject({message: 'Email gevonden is van google'});
    //                 } else {
    //                     resolve(providers);
    //                 }
    //             } else {
    //                 reject({message: 'Geen emails gevonden'});
    //             }
    //         })
    //         .catch((e) => {
    //             switch (e.code) {
    //                 case 'auth/invalid-email':
    //                     reject({message: 'Geen geldig email adres'});
    //                     break;
    //                 default:
    //                     reject({message: 'Er ging iets onverwachts fout'});
    //                     break;
    //             }
    //         });
    // });

    const Logout = async () => new Promise(async (resolve, reject) => {
        firebase.auth()
            .signOut()
            .then(() => {
                resolve('Succesvol uitgelogd');
                dispatch(clearUser())
                history.push('/');
            })
            .catch((e) => {
                reject(e);
            });
    });


    return {
        FirebaseLogin, FirebaseReset, Logout
    };
};
