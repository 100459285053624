import {createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import React from "react";
import orderBy from "lodash/orderBy";
import rawWorkOrdersItems, {rawItems} from "../../data/workorders/WorkOrdersItems";

const initialState = {
    columns: rawWorkOrdersItems,
    cards: rawItems,
    modal: false,
    modalCreate: false,
    modalEdit: false,
    modalContent: {},
    modalCreateContent: {},
    toggleEditModal: {},
};
export const slice = createSlice({
    name: 'workorders',
    initialState,
    reducers: {
        addColumn: (state, action) => {
            if (state.columns.find(item => item.id === action.payload.id)) {
                toast(<span className="text-warning">Item already exists in the list!</span>);
            } else {
                if (action.payload.start) {
                    state.columns = [action.payload.data, ...state.columns]
                } else {
                    state.columns = [...state.columns, action.payload.data]
                }
            }
        },
        removeColumn: (state, action) => {
            if (action.payload.id && action.payload.id !== 0) {
                state.columns = state.columns.filter(item => item.id !== action.payload.id);
            }
        },
        toggleModal: (state, action) => {
            state.modal = !state.modal;
        },
        toggleCreateModal: (state, action) => {
            state.modalCreate = !state.modalCreate;
            state.modalCreateContent = action.payload;
        },
        toggleEditModal: (state, action) => {
            state.modalEdit = !state.modalEdit;
            state.modalEditContent = action.payload;
        },
        setModalContent: (state, action) => {
            state.modalContent = action.payload;
        },
        enableModal: (state)=>{
            state.modal = true;
        },
        editColumn: (state, action) => {
            if (action.payload.id && action.payload.id !== 0) {
                if (action.payload.start) {
                    const filteredState = state.columns.filter(item => item.id !== action.payload.id);
                    state.columns = [action.payload.data, ...filteredState];
                }
                state.columns = state.columns.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload.data
                    } else {
                        return item;
                    }
                });
            } else {
                console.log('no id');
            }
        },
        orderColumns: (state, action) => {
            if (action.payload.sortBy && action.payload.order) {
                state.columns = orderBy(state.columns, action.payload.sortBy, action.payload.order)
            }
        },

        addCard: (state, action) => {
            if (state.cards.find(item => item.id === action.payload.id)) {
                toast(<span className="text-warning">Item already exists in the list!</span>);
            } else {
                if (action.payload.start) {
                    state.cards = [action.payload.data, ...state.cards]
                } else {
                    state.cards = [...state.cards, action.payload.data]
                }
            }
        },
        removeCard: (state, action) => {
            if (action.payload.id && action.payload.id !== 0) {
                state.cards = state.cards.filter(item => item.id !== action.payload.id);
            }
        },
        editCard: (state, action) => {
            if (action.payload.id && action.payload.id !== 0) {
                if (action.payload.start) {
                    const filteredState = state.cards.filter(item => item.id !== action.payload.id);
                    state.cards = [action.payload.data, ...filteredState];
                }
                state.cards.map(item => (item.id === action.payload.id ? action.payload.data : item));
            }
        },
        orderCards: (state, action) => {
            if (action.payload.sortBy && action.payload.order) {
                state.cards = orderBy(state.cards, action.payload.sortBy, action.payload.order)
            }
        },
    },
});
export const {addCard,toggleEditModal,setModalContent,toggleCreateModal,enableModal, toggleModal, addColumn, editCard, editColumn, removeCard, removeColumn, orderCards, orderColumns} = slice.actions;

export default slice.reducer;
