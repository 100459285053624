import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  homeRoutes,
    workOrdersRoutes,
} from '../../routes';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  return (
    <>
        <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink exact className="nav-link" to="/">
                {homeRoutes.name}
            </NavLink>
        </NavItem>

        {/*<NavItem onClick={handleSetNavbarCollapsed}>*/}
        {/*    <NavLink className="nav-link" to="/workorders">*/}
        {/*        {workOrdersRoutes.name}*/}
        {/*    </NavLink>*/}
        {/*</NavItem>*/}
        {/*<NavItem onClick={handleSetNavbarCollapsed}>*/}
        {/*  <NavLink className="nav-link" to="/changelog">*/}
        {/*    Logboek*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
