import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'


const firebaseConfig = {
  apiKey: "AIzaSyDtqdPk2JMTaSuat_KMJ1fLXynYJTbKU_w",
  authDomain: "mscconnect-59dc9.firebaseapp.com",
  projectId: "mscconnect-59dc9",
  storageBucket: "mscconnect-59dc9.appspot.com",
  messagingSenderId: "747754882635",
  appId: "1:747754882635:web:7cbd22b755a1a89d20efce",
  measurementId: "G-1KXKJC6JJD"
}
firebase.initializeApp(firebaseConfig);
export const functions = firebase.app().functions('europe-central2');
//
if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.2.15' || window.location.hostname === '192.168.2.128') {
  console.log('setting host name');
  // firebase.functions().useEmulator('localhost', 5001);
  functions.useEmulator('localhost', 5001);
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.auth().useEmulator('http://localhost:9098/');
}

export default firebase
