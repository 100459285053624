import React from 'react';
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {CloseButton, Fade} from '../components/common/Toast';
import DashboardLayout from '../layouts/DashboardLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import {useSelector} from "react-redux";
import Auth from "../features/auth/Auth";


const Navigation = () => {
    const user = useSelector(state => state.auth.loggedIn);

    return (
        <Router fallback={<span/>}>
            <Switch>
                <Route path="/errors" component={ErrorLayout}/>
                {user ?
                    <Route component={DashboardLayout}/>
                    :
                    <Route component={Auth}/>
                }

            </Switch>
            <ToastContainer transition={Fade} closeButton={<CloseButton/>} position={toast.POSITION.BOTTOM_LEFT}/>
        </Router>
    );
};

export default Navigation;
