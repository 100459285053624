import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import firebase from "../api/firebase";
import SidePanelModal from "../components/side-panel/SidePanelModal";
import {useDispatch, useSelector} from "react-redux";
import {setJobs} from "../features/jobs/JobsSlics";

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const dispatch = useDispatch();
  // const isWorkOrders = getPageName('WorkOrders');
  useEffect(() => {
    const subscriber = firebase.firestore()
      .collection('jobs').where("vendor.Description","==","MSC")
      .onSnapshot(querySnapshot => {
        const newJobs = [];

        querySnapshot.forEach(documentSnapshot => {
          newJobs.push({
            ...documentSnapshot.data(),
            uid: documentSnapshot.id,
            value: documentSnapshot.id,
            label:`${documentSnapshot.data().description || '-'} (${documentSnapshot.data().equipment?.code}) (LUB${documentSnapshot.data().equipment?.ProcessFunction})`
          });
        });

        dispatch(setJobs(newJobs))
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);
  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isWorkOrders={false} navbarStyle={navbarStyle} />}
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <DashboardRoutes />
          </Switch>
          <Footer />
        </div>
        <SidePanelModal path={location.pathname} />
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
