import React, {Fragment, useEffect, useState} from 'react';
import {Row, Col, Card, CardBody, Button, InputGroup, CustomInput} from 'reactstrap';
import CountUp from 'react-countup';
import CardSummary from './CardSummary';
import ActiveUsersBarChart from './ActiveUsersBarChart';
import PaymentsLineChart from './PaymentsLineChart';
import {toast} from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';

import loadable from '@loadable/component';
import firebase, {functions} from "../../api/firebase";
import {useSelector} from "react-redux";
// import DashBoardDepositStatus from './DashboardDepositStatus';
const JobsTable = loadable(() => import('./JobsTable'));
// const ActiveUsersMap = loadable(() => import('./ActiveUsersMap'));

const Dashboard = () => {
    // State
    const [isSelected, setIsSelected] = useState(false);
    const [customers, setCustomers] = useState(0);
    const [workorders, setWorkorders] = useState(0);
    const [loading,setLoading] = useState(false);
  const {
    list: jobs
  } = useSelector(state => state.jobs)
    return (
        <Fragment>
            {/*<PaymentsLineChart />*/}
            {/*<DashBoardDepositStatus />*/}
            <div className="card-deck">
                <CardSummary to={'/'} rate="0.0%" title="Jobs" color="info" linkText="Bekijk jobs">
                    <CountUp start={0} end={jobs.length} duration={2} separator="," decimal="."/>
                </CardSummary>
            </div>
            <Card className="mb-3">
                <FalconCardHeader title="Jobs" light={false}>
                    {/*{isSelected ? (*/}
                    {/*    <InputGroup size="sm" className="input-group input-group-sm">*/}
                    {/*        <CustomInput type="select" id="bulk-select">*/}
                    {/*            <option>Bulk actions</option>*/}
                    {/*            <option value="Refund">Refund</option>*/}
                    {/*            <option value="Delete">Delete</option>*/}
                    {/*            <option value="Archive">Archive</option>*/}
                    {/*        </CustomInput>*/}
                    {/*        <Button color="falcon-default" size="sm" className="ml-2">*/}
                    {/*            Apply*/}
                    {/*        </Button>*/}
                    {/*    </InputGroup>*/}
                    {/*) : (*/}
                    {/*    <Fragment>*/}
                            <ButtonIcon spin={loading} onClick={()=>{
                              setLoading(true);
                              const addMessage = functions.httpsCallable('importUltimoHttps');
                              addMessage()
                                .then((result) => {
                                  // Read result of the Cloud Function.
                                  console.log(result);
                                  toast.success('Succesvol gesynchroniseerd');
                                }).finally(() => {
                                setLoading(false);
                              }).catch(e => {
                                console.log(Object.keys(e));
                                console.log(e.response);
                                console.log(e.details);
                                console.log(e.code);

                                console.log(e);
                                toast.error('Synchroniseren ging niet goed');
                              });
                            }} icon="sync" transform="shrink-3 down-2" color="falcon-default" size="sm">
                                Sync
                            </ButtonIcon>
                    {/*        <ButtonIcon icon="filter" transform="shrink-3 down-2" color="falcon-default" size="sm"*/}
                    {/*                    className="mx-2">*/}
                    {/*            Filter*/}
                    {/*        </ButtonIcon>*/}
                    {/*        <ButtonIcon icon="external-link-alt" transform="shrink-3 down-2" color="falcon-default"*/}
                    {/*                    size="sm">*/}
                    {/*            Export*/}
                    {/*        </ButtonIcon>*/}
                    {/*    </Fragment>*/}
                    {/*)}*/}
                </FalconCardHeader>
                <CardBody className="p-0">
                    <JobsTable setIsSelected={setIsSelected}/>
                </CardBody>
            </Card>
            {/*<ButtonIcon className="mt-3"  icon={loading ? 'spinner' : 'sync'} spin={loading} transform="shrink-3" onClick={() => {*/}
            {/*    // firebase.firestore().collection('tasks').add({*/}
            {/*    //     options: {},*/}
            {/*    //     performAt: new Date(),*/}
            {/*    //     status: "scheduled",*/}
            {/*    //     worker: 'testFunction'*/}
            {/*    // }).then(() => {*/}
            {/*    //     console.log('updated')*/}
            {/*    // });*/}
            {/*    setLoading(true);*/}
            {/*    const addMessage = firebase.functions().httpsCallable('sync');*/}
            {/*    addMessage({})*/}
            {/*        .then((result) => {*/}
            {/*            // Read result of the Cloud Function.*/}
            {/*            console.log(result);*/}
            {/*            toast.success('Sync succesvol');*/}
            {/*        }).finally(()=>{*/}
            {/*        setLoading(false);*/}
            {/*    });*/}
            {/*}}/>*/}
            {/*<Row noGutters>*/}
            {/*  <Col lg="4" className="pr-lg-2">*/}
            {/*    <ActiveUsersBarChart />*/}
            {/*  </Col>*/}
            {/*  <Col lg="8" className="pl-lg-2">*/}
            {/*    <ActiveUsersMap />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
        </Fragment>
    );
};

export default Dashboard;
