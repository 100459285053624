import {createSlice} from '@reduxjs/toolkit';
import React from "react";
const initialState = {
  list: [],
};
export const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs: (state, action) => {
      state.list = action.payload
    },
  },
});
export const {
  setJobs
} = slice.actions;

export default slice.reducer;
