import { configureStore } from '@reduxjs/toolkit';
// import ThemeReducer from '../features/theme/ThemeSlice';
import AuthReducer from '../features/auth/AuthSlice';
import WorkordersReducer from '../features/workorders/WorkOrdersSlice';
import JobsReducer from '../features/jobs/JobsSlics';


export default configureStore({
  reducer: {
    // theme: ThemeReducer,
    auth: AuthReducer,
    workorders: WorkordersReducer,
    jobs: JobsReducer,
  },
});
