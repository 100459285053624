export const homeRoutes = {
  name: 'Jobs',
  to: '/',
  exact: true,
  icon: 'chart-pie',
};
export const invoicesRoutes = {
  name: 'Facturen',
  to: '/invoices',
  exact: true,
  icon: 'file',
};

export default [
  homeRoutes,
  invoicesRoutes
];
