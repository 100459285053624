import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, Row, Col, FormGroup, Input, CustomInput, Label} from 'reactstrap';
import {AuthService} from "../AuthService";

const LoginForm = ({hasLabel}) => {
    // State
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const {FirebaseLogin} = AuthService();

    // Handler
    const handleSubmit = async(e) => {
        e.preventDefault();

        await FirebaseLogin(email, password,remember).then((e) => {
            console.log(e)
            toast.success(`Succesvol ingelogd als ${email}`);

        }).catch((e) => {
            toast.error(e.message);
        });
    };

    useEffect(() => {
        setIsDisabled(!email || !password);
    }, [email, password]);

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                {hasLabel && <Label>Email</Label>}
                <Input
                    placeholder={!hasLabel ? 'Email' : ''}
                    value={email}
                    onChange={({target}) => setEmail(target.value)}
                    type="email"
                />
            </FormGroup>
            <FormGroup>
                {hasLabel && <Label>Wachtwoord</Label>}
                <Input
                    placeholder={!hasLabel ? 'Wachtwoord' : ''}
                    value={password}
                    onChange={({target}) => setPassword(target.value)}
                    type="password"
                />
            </FormGroup>
            <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                    <CustomInput
                        id="customCheckRemember"
                        label="Onthoud mij"
                        checked={remember}
                        onChange={({target}) => setRemember(target.checked)}
                        type="checkbox"
                    />
                </Col>
                <Col xs="auto">
                    <Link className="fs--1" to={`/forget-password`}>
                        Wachtwoord vergeten?
                    </Link>
                </Col>
            </Row>
            <FormGroup>
                <Button color="primary" block className="mt-3" disabled={isDisabled}>
                   Inloggen
                </Button>
            </FormGroup>
        </Form>
    );
};

LoginForm.propTypes = {
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    hasLabel: false
};

export default LoginForm;
